import React from "react";
import Layout from "../../components/Layout";
import BlogRoll from "../../components/BlogRoll";
import { Helmet } from "react-helmet";

export default class BlogIndexPage extends React.Component {
  render() {
    const title = "Blog at Schoolhouse by the Sea: Supporting Learning at Home";
    const description =
      "Periodically Schoolhouse by the Sea publishes blog articles calling in a new season or providing an update on our happenings or publishing a special announcement.";
    return (
      <Layout>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />
        </Helmet>
        <div
          className="full-width-image-container"
          style={{
            backgroundImage: `url('/img/pebbles-on-the-beach.jpg')`,
          }}
        >
          <h1
            className="has-text-weight-bold is-size-1"
            style={{
              backgroundColor: "#1FA8C2",
              color: "white",
              padding: "1rem 1.5rem",
            }}
          >
            Latest Articles
          </h1>
        </div>
        <section className="section">
          <div className="container">
            <div className="content">
              <BlogRoll />
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
